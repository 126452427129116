'use strict';

angular.module('administrationPortal').controller('AdminUsersCtrl', ['$location', '$adminUserService', '$adminAccessService', '$timeout', function ($location, $adminUserService, $adminAccessService, $timeout) {
    const self = this;
    const validEmailRegex = /^[A-Z0-9._&%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    self.error = null;
    self.user = null;
    self.groups = [];
    self.roles = [];
    self.mails = [];
    self.mailSuppression = null;
    self.isPartner = false;
    self.findUser = findUser;
    self.removeSuppression = removeSuppression;
    self.getGrants = getGrants;
    self.enablePartner = enablePartner;
    self.disablePartner = disablePartner;
    self.objectHasValue = (obj) => Object.keys(obj).length !== 0;
    self.getColorForMailType = (type) => {
        switch (type) {
            case 'injection':
                return 'blue';
            case 'delivery':
                return 'green';
            case 'policy_rejection':
            case 'bounce':
                return 'red';
            case 'delay':
                return 'orange';
            default:
                return 'purple';
        }
    };
    self.getIconForMailType = (type) => {
        switch (type) {
            case 'injection':
                return 'fa-play-circle';
            case 'delivery':
                return 'fa-check-circle';
            case 'policy_rejection':
            case 'bounce':
                return 'fa-times-circle';
            case 'delay':
                return 'fa-clock-o';
            default:
                return 'fa-question-circle';
        }
    };

    init();

    function init() {
        const username = $location.search().username;
        if (username) {
            self.query = username;
            findUser(username);
        }
    }

    function findUser(username) {
        self.error = null;
        self.user = null;

        if (validEmailRegex.test(username)) {
            self.mails = $adminUserService.getUserMails(username);
            self.mailSuppression = $adminUserService.getSuppression(username);
        }

        $adminUserService.getUser(username).$promise
            .then((user) => {
                self.user = user;
                $location.search('username', username);
                getGroups(user);
                getRoles(user);

                // For users that have non email addresses as their usernames (SSO)
                if (user.settings.email !== username) {
                    self.mails = $adminUserService.getUserMails(username);
                    self.mailSuppression = $adminUserService.getSuppression(username);
                }
            })
            .catch((err) => {
                self.error = err.status === 404 ? 'User not found' : 'Server error: ' + err.data.message;
                self.user = null;
                $location.search(''); // remove search
                self.groups = [];
                self.roles = [];
            });
    }

    function removeSuppression(email) {
        $adminUserService.removeSuppression(email).$promise.then(() => {
            // It takes a few sec before SparkPost has updated it from the list
            $timeout(() => {
                findUser(email);
            }, 1000);
        });
    }

    function getGrants(user) {
        return user.grants.filter(grant => grant.resourceType === 'CUSTOMER' || grant.resourceType === 'SYSTEM');
    }

    function getGroups(user) {
        self.groups = {};
        getGrants(user).map(x => x.customerId).forEach(customerId => {
            $adminAccessService.getGroups(customerId).then(groups => {
                groups.forEach(group => {
                    group.customerId = customerId;
                    self.groups[group.id] = group;
                });
            });
        });
    }

    function getRoles(user) {
        self.roles = {};
        self.isPartner = false;
        getGrants(user).forEach(grant => {
            $adminAccessService.getRoles(grant.customerId).then(roles => {
                const partnerRole = roles.find(role => role.permissions.indexOf('CREATE_CUSTOMERS') !== -1);
                if (partnerRole && grant.roleId === partnerRole.id) {
                    self.isPartner = true;
                }
                roles.forEach(role => self.roles[role.id] = role);
            });
        });
    }

    function enablePartner(user) {
        self.saving = true;
        $adminAccessService.addPartnerGrant(user.id).then(() => findUser(user.username)).finally(() => self.saving = false);
    }

    function disablePartner(user) {
        self.saving = true;
        $adminAccessService.removePartnerGrant(user.id).then(() => findUser(user.username)).finally(() => self.saving = false);
    }

}]);
