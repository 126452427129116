'use strict';

angular.module('administrationPortal').run(['$rootScope', '$state', '$countryService', function ($rootScope, $state, $countryService) {
  $rootScope.$state = $state;
  $rootScope.$countryService = $countryService;
}]);

angular.module('administrationPortal').config(['localStorageServiceProvider', function (localStorageServiceProvider) {
  localStorageServiceProvider.setPrefix('moreAdminPortal');
}]);

angular.module('administrationPortal').config(['laddaProvider', function (laddaProvider) {
  laddaProvider.setOption({ style: 'expand-right' });
}]);

angular.module('administrationPortal').config(['$translateProvider', function ($translateProvider) {
  $translateProvider.useSanitizeValueStrategy(null);
}]);

angular.module('administrationPortal').config(['$locationProvider', function ($locationProvider) {
  $locationProvider.html5Mode(true);
}]);

angular.element(document).ready(function () {
  angular.bootstrap(document, ['administrationPortal']);
});
