'use strict';

angular.module('administrationPortal').controller('AdminFinancialTreesCtrl', ['$adminTreeService', function ($adminTreeService) {
  const self = this;

  self.columns = [
    {id: 'year', name: 'Year'},
    {id: 'month', name: 'Month'},
    {id: 'totalAmount', name: 'Total Trees'}
  ];

  init();

  ////////
  function init() {
    $adminTreeService.overview().$promise.then(trees => {
      self.trees = trees.sort((a, b) => b.year - a.year || b.month - a.month);
    });
  }

}]);
