'use strict';

angular.module('administrationPortal').controller('AccessDeniedCtrl', ['$scope', '$state', '$securityService', function ($scope, $state, $securityService) {

  $scope.hasSession = function () {
    return $securityService.isLoggedIn();
  };

  $scope.logout = function () {
    $securityService.logout();
  };
}]);
