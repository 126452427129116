'use strict';

angular.module('administrationPortal').controller('AdminUtilsPartnersCtrl', ['moreConstants', '$scope', '$downloadUtil', function (moreConstants, $scope, $downloadUtil) {
    const self = this;
    self.exporting = false;

    $scope.exportPartners = () => {
        self.exporting = true;
        $downloadUtil.downloadFileWithAuth(moreConstants.apiEndpoint + '/admin/partners', function (data) {
            saveAs(data, 'partners.csv');
            self.exporting = false;
        });
    };
}]);
