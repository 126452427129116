'use strict';

angular.module('administrationPortal').controller('AdminUtilsAccountOwnersCtrl', ['moreConstants', '$scope', '$downloadUtil', function (moreConstants, $scope, $downloadUtil) {
    const self = this;
    self.exporting = false;

    $scope.exportAccountOwners = () => {
        self.exporting = true;
        $downloadUtil.downloadFileWithAuth(moreConstants.apiEndpoint + '/admin/account-owners', function (data) {
            saveAs(data, 'account-owners.csv');
            self.exporting = false;
        });
    };
}]);
