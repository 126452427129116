'use strict';

angular.module('administrationPortal').controller('AdminQueueJobCtrl', ['$scope', 'task', '$adminQueueService', function ($scope, task, $adminQueueService) {

  var self = this;
  self.task = task;
  self.triggered = false;
  self.requestRetryJob = requestRetryJob;

  ////////
  function requestRetryJob(task) {
    $adminQueueService.requestRetry(task.id);
    self.triggered = true;
  }
}]);