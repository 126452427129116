'use strict';

angular.module('administrationPortal').controller('AdminFinancialIndexCtrl', function () {

  var self = this;

  self.menuItems = [
    {name: 'Trees', state: 'trees'}
  ];
});
