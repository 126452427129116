'use strict';

angular.module('administrationPortal').controller('AdminQueueCtrl', ['$scope', '$adminQueueService', '$simpleFilterService', '$sidePanel', '$dateHelper', function ($scope, $adminQueueService, $simpleFilterService, $sidePanel, $dateHelper) {

  var self = this;

  self.filter = {
    currentPage: 0,
    pageSize: 20,
    sortInfo: {
      directions: [-1],
      fields: ['startTime']
    },
    query: []
  };
  self.searchEnabled = false;
  self.columns = [{ id: 'handlerClass', name: 'Handler Class' }, { id: 'id', name: 'Id' }, { id: 'state', name: 'State' }, { id: 'startTime', name: 'Start' }, { id: 'endTime', name: 'End' }];
  self.queueStats = {};

  self.getJobs = getJobs;
  self.showJob = showJob;

  ////////
  function getJobs(filter) {
    var simpleFilter = $simpleFilterService.gridFilterToSimpleFilter(filter);
    return $adminQueueService.filterQueue(filter.currentPage, simpleFilter).$promise.then(function (elements) {
      elements = elements.map(function (element) {
        element.handlerClass = classSimpleName(element.handlerClass);
        element.startTime = formatDateTimeAsString(element.startTime);
        element.endTime = formatDateTimeAsString(element.endTime);
        return element;
      });
      return $adminQueueService.queueStats().$promise.then(function (stats) {
        self.queueStats = stats;
        return {
          listedObjects: elements,
          totalItems: stats.size
        };
      });
    });
  }

  function showJob(task) {
    $sidePanel.open({
      template: require('../../../administration-portal/templates/portal/queue.job.html'),
      windowClass: 'sidepanel-large',
      resolve: {
        task: function () {
          return task;
        }
      },
      controller: 'AdminQueueJobCtrl as ctrl'
    }).then(function (result) {
      if (result && result.triggered === true) {
        $scope.$broadcast('paginatedmoregrid.reload');
      }
    });
  }

  function classSimpleName(handlerClass) {
    if (!handlerClass) {
      return;
    }
    return handlerClass.substring(handlerClass.lastIndexOf('.') + 1);
  }

  function formatDateTimeAsString(dateTime) {
    if (!dateTime) {
      return '';
    }
    return $dateHelper.dateTimeAsString(new Date(dateTime));
  }
}]);