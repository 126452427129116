'use strict';

angular.module('MoreValidation').directive('fieldError', ['$compile', function ($compile) {
  return {
    restrict: 'A',
    scope: true,
    require: 'ngModel',
    link: function (scope, element, attrs) {
      var name = attrs.ngModel;
      if (attrs.fieldErrorPrefix) {
        name = name.replace(attrs.fieldErrorPrefix + '.', '');
      }
      var el = $compile('<div class="help-block form-input-error"><ul><li data-ng-repeat="error in ' + attrs.fieldError + '[\'' + name + '\']">{{ error }}</li></ul></div>')(scope);
      scope.$watch(attrs.fieldError + '.' + name, function (newValue) {
        var formGroup = element.closest('.form-group');
        if (newValue && newValue.length > 0) {
          formGroup.addClass('has-error');
        } else {
          formGroup.removeClass('has-error');
        }
      });
      element.parent().append(el);
    }
  };
}]);