'use strict';

angular.module('administrationPortal').controller('AdminGDPRCtrl', ['$scope', '$adminUserService', '$modalService', function ($scope, $adminUserService, $modalService) {
  const self = this;

  self.deletingUser = false;
  self.successMessage = false;

  self.removeUser = () => {
    $modalService.confirmModal({
      title : 'USER_DELETE',
      message : 'USER_DELETE_MESSAGE',
      confirmButtonTitle : 'DELETE',
      showExclamation : true,
      confirmButtonStyle : 'danger'
    }).then(() => {
      self.successMessage = false;
      self.deletingUser = true;
      $adminUserService.removeUser(self.userName).$promise.then(() => {
        self.deletingUser = false;
        self.successMessage = true;
      }, (err) => {
        self.deletingUser = false;
        $modalService.errorModal(err.data && err.data.message);
      });
    });
  };
}]);
